import React, { useState, useEffect } from "react";
import { Table, Input, Select, Menu, Dropdown, Pagination, DatePicker, Spin } from "antd";
import { MdOutlineArrowDropDownCircle } from "react-icons/md";
import { SearchOutlined } from "@ant-design/icons";
import { BsThreeDots } from "react-icons/bs";
import { IoMdPrint, IoIosArrowDown } from "react-icons/io";
import Instance from "../../AxiosConfig";
import moment from "moment";
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // Optional: For table support in jsPDF
import InvoiceLogo1 from '../../Assets/images/white-bg-logo.png'

const DeliveredList = () => {
  const [searchText, setSearchText] = useState("");
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });
  const [selectedDate, setSelectedDate] = useState(null);


  const handleExportRows = (record) => {
    console.log("pdfdata:", record);
    const doc = new jsPDF();

    // General table data
    const tableData = (record.orderItems || []).map((item) => [
      item.title,
      `${item.totalPrice}`,
      item.quantity,
      item.orderItems[0].brand,
      // record.paymentDetails?.paymentStatus || 'Pending', // Adjust as per your data structure
      `${item.discountPercentage}%`,
      `${item.totalDiscountedPrice}`,
    ]);

    const tableHeaders = [
      "PRODUCT NAME",
      "PRICE",
      "QTY",
      "BRAND",
      // "PAYMENT STATUS",
      "DISCOUNT",
      "TOTAL",
    ];

    doc.setFillColor("#fff");
    doc.rect(0, 0, doc.internal.pageSize.width, 40, "F");

    const logoImage = new Image();
    logoImage.src = InvoiceLogo1;

    logoImage.onload = function () {
      doc.addImage(logoImage, "PNG", 15, 10, 50, 20);

      doc.setFontSize(11);
      doc.setTextColor(80);

      const baseX = 95;
      const baseY = 12;
      const lineHeight = 6;

      const labelX = 136;
      const valueX = 165;

      doc.text(`Order ID:`, labelX, baseY);
      doc.text(`Name:`, labelX, baseY + lineHeight);
      doc.text(`Order date:`, labelX, baseY + 2 * lineHeight);
      doc.text(`Order Status:`, labelX, baseY + 3 * lineHeight);
      doc.text(`Payment Status:`, labelX, baseY + 4 * lineHeight);


      doc.text(`${record.orderId}`, valueX, baseY);
      doc.text(`${record.username}`, valueX, baseY + lineHeight);
      doc.text(`${record.orderDate}`, valueX, baseY + 2 * lineHeight);
      doc.text(`${record.orderStatus}`, valueX, baseY + 3 * lineHeight);
      doc.text(`${record.paymentDetails?.paymentStatus}`, valueX, baseY + 4 * lineHeight);



      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      const borderMargin = 7;

      doc.rect(borderMargin, borderMargin, pageWidth - 2 * borderMargin, pageHeight - 2 * borderMargin);
      doc.autoTable({
        head: [tableHeaders],
        body: tableData,
        startY: 40,
        headStyles: {
          fillColor: [27, 58, 87],
          textColor: [255, 255, 255],
        },
        styles: {
          cellPadding: 4,
          minCellHeight: 8,
          lineWidth: 0.2,
          lineColor: [200, 200, 200],
        },
        columnStyles: {
          0: { cellWidth: 37 },
          3: { cellWidth: 37 },
          4: { cellWidth: 29 },
        },
      });

      const processTableData = (data) => {
        let previousBrand = '';
        let rowspan = 1;
        const processedData = [];

        data.forEach((row, index) => {
          if (row[0] === previousBrand) {
            rowspan++;
          } else {
            if (previousBrand) {
              processedData.push([previousBrand, '', '', '', '', '', '']); // placeholder for the merged row
            }
            previousBrand = row[0];
            rowspan = 1;
          }

          const newRow = [...row];
          newRow[0] = rowspan === 1 ? previousBrand : ''; // Clear the brand cell if it's not the first row of the rowspan
          processedData.push(newRow);
        });

        if (previousBrand) {
          processedData.push([previousBrand, '', '', '', '', '', '']);
        }

        return processedData;
      };

      const steelTableData = (record.orderItems || []).flatMap(item =>
        item.orderItems
          .filter(nestedItem => nestedItem.steelProduct)
          .sort((a, b) => parseFloat(a.steelSize) - parseFloat(b.steelSize))
          .map(nestedItem => [
            nestedItem.brand,
            nestedItem.title,
            nestedItem.steelSize,
            `${(nestedItem.price?? 0).toFixed(2)}`,
            `${nestedItem.discountPercentage}%`,
            nestedItem.quantity,
            `${(nestedItem.discountedPrice?? 0).toFixed(2)}`,
          ])
      );

      const processedSteelTableData = processTableData(steelTableData);

      let lastBrand = '';
      for (let i = 0; i < steelTableData.length; i++) {
        if (steelTableData[i][0] === lastBrand) {
          steelTableData[i][0] = ''; 
        } else {
          lastBrand = steelTableData[i][0]; 
        }
      }

      if (steelTableData.length > 0) {
        const steelTableHeaders = [
          "BRAND",
          "PRODUCT NAME",
          "STEEL SIZE",
          "PRICE",
          "DISC %",
          "QTY",
          "DISC PRICE",
        ];

        doc.autoTable({
          head: [steelTableHeaders],
          body: steelTableData,
          startY: doc.lastAutoTable.finalY + 10,
          headStyles: {
            fillColor: [27, 58, 87], 
            textColor: [255, 255, 255], 
          },
          styles: {
            cellPadding: 4,
            minCellHeight: 8,
            lineWidth: 0.2,
            lineColor: [200, 200, 200],
          },
          columnStyles: {
            0: { cellWidth: 30 },
            2: { cellWidth: 20 },
            4: { cellWidth: 19 },
          },

        });
      }



      const finalY = doc.lastAutoTable.finalY;
      const labelMarginLeft = 140;
      const valueMarginLeft = 177;

      const labelMarginLeft2 = 14;
      const valueMarginLeft2 = 41;

      doc.setFontSize(12);
      doc.setTextColor(80);

      doc.text("Subtotal:", labelMarginLeft, finalY + 10);
      doc.text("Discount Amount:", labelMarginLeft, finalY + 20);
      if (record.couponApplied) {
        doc.text("Coupon Savings:", labelMarginLeft, finalY + 30);
      }
      doc.text("Shipping:", labelMarginLeft, finalY + 30 + (record.couponApplied ? 10 : 0));
      doc.line(labelMarginLeft, finalY + 33 + (record.couponApplied ? 10 : 0), valueMarginLeft + 20, finalY + 33 + (record.couponApplied ? 10 : 0));
      doc.text("Total Amount:", labelMarginLeft, finalY + 40 + (record.couponApplied ? 10 : 0));
      if(record.paymentType=="COD"){
        doc.text(`Amount Received:`, labelMarginLeft, finalY + 50 + (record.couponApplied ? 10 : 0));
      doc.text(`Balance Amount:`, labelMarginLeft, finalY + 60 + (record.couponApplied ? 10 : 0));
      }
      


      doc.text(`${(record.totalPrice?? 0).toFixed(2)}`, valueMarginLeft, finalY + 10);
      doc.text(`-${(record.discount?? 0).toFixed(2)}`, valueMarginLeft, finalY + 20);
      if (record.couponApplied) {
        doc.text(`-${(record.couponDiscountedPrice?? 0).toFixed(2)}`, valueMarginLeft, finalY + 30);
      }
      doc.text(`${(record.deliveryFee?? 0).toFixed(2)}`, valueMarginLeft, finalY + 30 + (record.couponApplied ? 10 : 0));
      doc.text(`${(record.totalPriceIncludeDeliveryFee?? 0).toFixed(2)}`, valueMarginLeft, finalY + 40 + (record.couponApplied ? 10 : 0));
      if(record.paymentType=="COD"){
        doc.text(`-${(record.paymentDetails.amountReceived?? 0).toFixed(2)}`, valueMarginLeft, finalY + 50 + (record.couponApplied ? 10 : 0));
        doc.text(`${((record.totalPriceIncludeDeliveryFee - record.paymentDetails.amountReceived)?? 0).toFixed(2)}`, valueMarginLeft, finalY + 60 + (record.couponApplied ? 10 : 0));
      }
     


      doc.setFontSize(11);
      const verticalOffset = record.paymentType === "COD" ? 10 : 0;
      const maxWidth = 90;
      const initialY = finalY + 10;



      doc.text("Delivery To:", labelMarginLeft2, initialY);
      doc.text("Payment Type:", labelMarginLeft2, initialY + 10);
      if (record.paymentType == "COD") {
        doc.text("Payment Id:", labelMarginLeft2, initialY + 20);
      }
      doc.text("Mobile No:", labelMarginLeft2, initialY + 20 + verticalOffset);
      doc.text("Address :", labelMarginLeft2, initialY + 28 + verticalOffset);


      doc.text(`${record.shippingAddress.addressType}`, valueMarginLeft2, initialY, { maxWidth });
      doc.text(`${record.paymentType}`, valueMarginLeft2, initialY + 10, { maxWidth });
      if (record.paymentType == "COD") {
        doc.text(`${record.paymentDetails.paymentId}`, valueMarginLeft2, initialY + 20, { maxWidth });
      }
      doc.text(`+91 ${record.shippingAddress.mobile}`, valueMarginLeft2, initialY + 20 + verticalOffset, { maxWidth });
      const lineHeight1 = 1.5;
      doc.text(
        `${record.shippingAddress.streetAddress}\n${record.shippingAddress.address}\n${record.shippingAddress.city}, ${record.shippingAddress.state}, ${record.shippingAddress.zipCode} ${record.shippingAddress.country}`,
        valueMarginLeft2,
        initialY + 28 + verticalOffset,
        { maxWidth, lineHeightFactor: lineHeight1 }
      );

      const totalPages = doc.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        doc.setFontSize(10);
        // Adjust the page number positioning
        doc.text(`Page ${i} of ${totalPages}`, pageWidth - 20, pageHeight - 1, {
          align: 'right',
        });
      }

      const pdfBase64 = doc.output('datauristring');
      const newWindow = window.open();
      newWindow.document.write(`<iframe width="100%" height="100%" src="${pdfBase64}"></iframe>`);
    };
  };


  const applyFilter = () => {
    let result = Array.isArray(data) ? [...data] : [];
    if (searchText) {
      result = result.filter(user =>
        user.orderId.toString().includes(searchText)
      );
    }

    if (selectedDate) {
      result = result.filter(user =>
        moment(user.createdDate).isSame(selectedDate, "day")
      );
    }
    setFilteredData(result);
    setPagination((prev) => ({
      ...prev,
      total: result.length,
      current: 1,
    }));
  };

  useEffect(() => {
    fetchOrdersData();
  }, []);

  useEffect(() => {
    applyFilter();
  }, [searchText, selectedDate, data]);

  const fetchOrdersData = async () => {
    setLoading(true);
    try {
      const url = "/api/v1/admin/orders/all/delivered";
      console.log("Fetching data from URL:", url);
      const response = await Instance.get(url);
      if (response.status === 200 || response.status === 201) {
        const processedData = groupOrderItemsByProductId(response.data);
        setData(processedData);
        applyFilter();
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
    setLoading(false);
  };
  const [expandedNestedRowKeys, setExpandedNestedRowKeys] = useState([]);

  const groupOrderItemsByProductId = (orders) => {
    return orders.map(order => {
      const groupedItems = order.orderItems.reduce((acc, item) => {
        const key = item.productId;

        if (!acc[key]) {
          acc[key] = {
            orderId: item.orderId,
            productId: item.productId,
            title: item.title,
            discountPercentage: item.discountPercentage,
            totalPrice: 0,
            totalDiscountedPrice: 0,
            quantity: 0,
            orderItems: [],
            steelProduct: item.steelProduct
          };
        }

        acc[key].quantity += item.quantity;
        acc[key].orderItems.push(item);
        acc[key].totalPrice += item.price;
        acc[key].totalDiscountedPrice += item.discountedPrice;

        if (acc[key].steelProduct && acc[key].totalPrice > 0) {
          const discount = ((acc[key].totalPrice - acc[key].totalDiscountedPrice) / acc[key].totalPrice) * 100;
          acc[key].discountPercentage = discount.toFixed(2);
        }

        return acc;
      }, {});

      return {
        ...order,
        orderItems: Object.values(groupedItems),
      };
    });
  };
  const handleNestedToggleDetails = (productId) => {
    setExpandedNestedRowKeys((prevKeys) => {
      const keys = prevKeys || [];
      return keys.includes(productId)
        ? keys.filter(key => key !== productId)
        : [...keys, productId];
    });
  };

  const handleToggleDetails = (record) => {
    const key = record.orderId;
    setExpandedRowKeys((prevKeys) =>
      prevKeys.includes(key) ? prevKeys.filter((k) => k !== key) : [...prevKeys, key]
    );
  };

  const handleTableChange = (page, pageSize) => {
    setPagination((prev) => ({
      ...prev,
      current: page,
      pageSize,
    }));
  };

  const handlePageSizeChange = (current, size) => {
    setPagination((prev) => ({
      ...prev,
      current,
      pageSize: size,
    }));
  };

  const handleDateChange = (date) => {
    setSelectedDate(date ? date.format("YYYY-MM-DD") : null);
  };

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };

  const columns = [
    {
      title: "OrderId",
      dataIndex: "orderId",
      render: (text) => `#${text.toString().slice(-4)}`,
    },
    {
      title: "Created",
      dataIndex: "createdDate",
      sorter: (a, b) => new Date(a.createdDate) - new Date(b.createdDate),
      render: (text) =>
        new Date(text).toLocaleDateString("en-us", {
          day: "numeric",
          month: "short",
          year: "numeric",
        }),
    },
    {
      title: "Customer",
      dataIndex: "username",
    },
    {
      title: "Total Price",
      dataIndex: "totalPriceIncludeDeliveryFee",
      render: (text) => (text !== null && text !== undefined ? text.toFixed(2) : "0.00"),
    },
    {
      title: "Total Items",
      dataIndex: "totalItem",
    },
    {
      title: "Payment Method",
      dataIndex: ["paymentDetails", "paymentMethod"],
      render: (paymentMethod) => paymentMethod || "NA",
    },
    {
      title: "Payment Status",
      dataIndex: ["paymentDetails", "paymentStatus"],
      render: (status) => {
        let color = "";
        let backgroundColor = "";
        if (status === "COMPLETED") {
          color = "#006400";
          backgroundColor = "#E0F8E0";
        } else if (status === "PENDING") {
          color = "#FF8C00";
          backgroundColor = "#FFE5B4";
        } else if (status === "CANCELLED") {
          color = "#8B0000";
          backgroundColor = "#FAD4D4";
        } else if (status === "PROCESSING") {
          color = "#0044CC";
          backgroundColor = "#E0F0FF";
        }
        return (
          <div style={{ width: "110px", backgroundColor, padding: "4px", borderRadius: "5px" }}>
            <span style={{ color, justifyContent: "center", display: "flex" }}>{status}</span>
          </div>
        );
      },
    },
    {
      title: "Order Status",
      dataIndex: "orderStatus",
      render: (status) => {
        let color = "";
        let backgroundColor = "";
        if (status === "DELIVERED") {
          color = "#006400";
          backgroundColor = "#E0F8E0";
        } else if (status === "IN_PROGRESS") {
          color = "#F97316";
          backgroundColor = "#fee7d7";
        } else if (status === "CANCELLED") {
          color = "#8B0000";
          backgroundColor = "#FAD4D4";
        }
        else if (status === "ORDER_PLACED") {
          color = "#0044CC";
          backgroundColor = "#E0F0FF";
        } else if (status === "PENDING") {
          color = "#FF8C00";
          backgroundColor = "#FFE5B4";
        } else if (status === "SHIPPED") {
          color = "#b7eb8f";
          backgroundColor = "#f6ffed";
        }
        return (
          <div style={{ width: "110px", backgroundColor, padding: "4px", borderRadius: "5px" }}>
            <span style={{ color, justifyContent: "center", display: "flex" }}>{status}</span>
          </div>
        );
      },
    },

    {
      title: "Exp",
      dataIndex: "expand",
      render: (_, record) => (
        <MdOutlineArrowDropDownCircle
          onClick={() => handleToggleDetails(record)}
          style={{ fontSize: "24px", cursor: "pointer", transform: expandedRowKeys.includes(record.orderId) ? 'rotate(180deg)' : 'rotate(0deg)' }}
        />
      ),
    },
    {
      title: (
        <>
          <IoMdPrint
            style={{ fontSize: "20px", verticalAlign: "middle", marginLeft: "4px" }}
          />{" "}
          Print
        </>
      ),
      dataIndex: "orderId",
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item onClick={() => handleExportRows(record)}>
                <IoMdPrint style={{ marginRight: "8px" }} />
                Print
              </Menu.Item>
            </Menu>
          }
        >
          <BsThreeDots style={{ fontSize: "24px" }} />
        </Dropdown>
      ),
    },
  ];



  const getPrintMenu = (record) => (
    <Menu>
      <Menu.Item key="print">
        <span>Print</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="user-list-section">
        <div className="filter-row">
          <div className="select-options media-manageorder">
            <Input
              placeholder="Search by order id"
              value={searchText}
              onChange={handleSearchTextChange}
              className="search-input-table me-2"
              prefix={<SearchOutlined />}
            />
          </div>
          <div className="search-table-container">
            <DatePicker
              placeholder="Filter by Date"
              format="YYYY-MM-DD"
              value={selectedDate ? moment(selectedDate) : null}
              onChange={handleDateChange}
            />
          </div>
        </div>
      </div>
      <div className="table-list">
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spin size="large" />
          </div>
        ) : (
          <>
            <Table
              columns={columns}
              dataSource={filteredData.slice(
                (pagination.current - 1) * pagination.pageSize,
                pagination.current * pagination.pageSize
              )}
              rowKey="orderId"
              loading={loading}
              pagination={false}
              expandable={{
                expandedRowRender: (record) => (
                  <div className="custom-child-table">
                    <Table
                      className="manageOrder-main-table"
                      dataSource={record.orderItems}
                      columns={[
                        { title: 'ORDERID', dataIndex: 'orderId', render: (text) => `#${text}` },
                        { title: "NAME", dataIndex: "title", key: "title" },
                        { title: "PRICE", dataIndex: "totalPrice", key: "totalPrice", render: (text) => (text !== null && text !== undefined ? text.toFixed(2) : "0.00"), },
                        { title: "QUANTITY", dataIndex: "quantity", key: "quantity" },
                        { title: "DISCOUNT %", dataIndex: "discountPercentage", key: "discountPercentage", render: (text) => `${text}%`, },
                        { title: "TOTAL", dataIndex: "totalDiscountedPrice", key: "totalDiscountedPrice", render: (text) => (text !== null && text !== undefined ? text.toFixed(2) : "0.00"), },
                        {
                          title: "EXPAND",
                          dataIndex: "expand",
                          render: (_, itemRecord) => (
                            itemRecord.steelProduct ? (
                              <MdOutlineArrowDropDownCircle
                                onClick={() => handleNestedToggleDetails(itemRecord.productId)} // Use productId here
                                style={{
                                  fontSize: "24px",
                                  cursor: "pointer",
                                  transform: expandedNestedRowKeys.includes(itemRecord.productId) ? 'rotate(180deg)' : 'rotate(0deg)'
                                }}
                              />
                            ) : null
                          ),
                        },
                      ]}
                      rowKey="productId"
                      pagination={false}
                      expandable={{
                        expandedRowRender: (nestedRecord) => (
                          <Table
                            dataSource={nestedRecord.orderItems}
                            columns={[
                              {
                                title: "STEEL SIZE",
                                dataIndex: "steelSize",
                                key: "steelSize",
                                sorter: (a, b) => parseFloat(b.steelSize) - parseFloat(a.steelSize),
                                defaultSortOrder: 'descend', 
                              }, { title: "PRICE", dataIndex: "price", key: "price", render: (text) => (text !== null && text !== undefined ? text.toFixed(2) : "0.00"), },
                              { title: "DISCOUNT %", dataIndex: "discountPercentage", key: "discountPercentage", render: (text) => `${text}%`, },
                              { title: "DISCOUNT PRICE", dataIndex: "discountedPrice", key: "discountedPrice", render: (text) => (text !== null && text !== undefined ? text.toFixed(2) : "0.00"), },
                              { title: "QUANTITY", dataIndex: "quantity", key: "quantity" },



                            ]}
                            rowKey="steelProductId"
                            pagination={false}
                            className="nested-table-details"
                          />
                        ),
                        rowExpandable: (nestedRecord) => !!nestedRecord.steelProduct,
                        expandedRowKeys: expandedNestedRowKeys,
                        onExpand: (expanded, nestedRecord) => {
                          handleNestedToggleDetails(nestedRecord.productId); 
                        },
                      }}
                    />
                    <div className="row">
                      <div className="col-lg-7 manageorders-media"
                        style={{ marginTop: 16, display: "flex", gap: "10px" }}>
                        <div className="address-label">
                            <p>Delivery To</p>
                            <p style={{ marginTop: -10 }}>PaymentType</p>
                            {record.paymentType == "COD" && <p style={{ marginTop: -10 }}>Payment Id</p>}
                            <p style={{ marginTop: -10 }}>Mobile No</p>
                            <p style={{ marginTop: -10 }}>Address</p>

                          </div>
                          <div>
                            <p>:</p>
                            <p style={{ marginTop: -10 }}>:</p>
                            {record.paymentType == "COD" &&<p style={{ marginTop: -10 }}>:</p>}
                            <p style={{ marginTop: -10 }}>:</p>
                            <p style={{ marginTop: -10 }}>:</p>
                          </div>
                          <div className="address-value">
                            <p>{record.shippingAddress.addressType}</p>
                            <p style={{ marginTop: -10 }}>{record.paymentType}</p>
                            {record.paymentType == "COD" && <p style={{ marginTop: -10 }}>{record.paymentDetails.paymentId}</p>}
                            <p style={{ marginTop: -10 }}>+91 {record.shippingAddress.mobile}</p>
                            <p style={{ marginTop: -10 }}>{record.shippingAddress.streetAddress}</p>
                            <p style={{ marginTop: -10 }}>{record.shippingAddress.address}</p>
                            <p style={{ marginTop: -10 }}>{record.shippingAddress.city}, {record.shippingAddress.state}, {record.shippingAddress.zipCode} {record.shippingAddress.country}</p>
                          </div>

                      </div>
                      {/* <div className="col-lg-4"></div> */}
                      <div
                        className="col-lg-4 manageorders-media"
                        style={{ marginTop: 16, marginLeft: "10px", display: "flex", gap: "40px" }}
                      >
                        <div className="summary-div">
                          <p>Subtotal :</p>
                          <p>Discount :</p>
                          {record.couponApplied && <p style={{ color: "#54C104" }}>Coupon Savings:</p>}
                          <p>Shipping :</p>
                          <p>_____________</p>
                          <p>Total :</p>
                          {record.paymentType === "COD" && <p>Amount Received:</p>}
                          {record.paymentType === "COD" && <p>Balance Amount:</p>}
                        </div>
                        <div>
                            <p> ₹ {(record.totalPrice?? 0).toFixed(2)}</p>
                            <p>
                              {" "}
                              -<span style={{ color: "#ce1b28" }}>₹ {(record.discount?? 0).toFixed(2)}</span>
                            </p>
                            {record.couponApplied && <p style={{ color: "#54C104" }}>-₹ {(record.couponDiscountedPrice??0).toFixed(2)}</p>}
                            <p> {(record.deliveryFee?? 0).toFixed(2)}</p>
                            <p style={{marginLeft:"-35px"}}>____________</p>
                            <p>₹ {(record.totalPriceIncludeDeliveryFee?? 0).toFixed(2)}</p>
                            {record.paymentType === "COD" && <p>-₹ {(record.paymentDetails.amountReceived??0).toFixed(2)}</p>}
                            {record.paymentType === "COD" && <p>₹ {((record.totalPriceIncludeDeliveryFee-record.paymentDetails.amountReceived)?? 0).toFixed(2)}</p>}
                          </div>
                      </div>
                    </div>
                  </div>
                ),
                expandedRowKeys,
                onExpand: (expanded, record) => {
                  handleToggleDetails(record);
                },
              }}
            />
          </>
        )}

        <div className="pagination-info">
          <p>
            Showing{" "}
            <Select
              defaultValue={pagination.pageSize}
              onChange={(value) => handlePageSizeChange(pagination.current, value)}
              style={{ width: 80 }}
              suffixIcon={<IoIosArrowDown />}
            >
              <Select.Option value={5}>5</Select.Option>
              <Select.Option value={10}>10</Select.Option>
              <Select.Option value={20}>20</Select.Option>
            </Select>{" "}
            out of {pagination.total}
          </p>
          <Pagination
            {...pagination}
            showSizeChanger={false}
            onChange={handleTableChange}
          />
        </div>
      </div>

    </>
  );
};

export default DeliveredList;
