import React, { useState, useEffect } from "react";
import { message, Modal, Rate, Spin } from "antd";
import Slider from "react-slick";
import { FaPlus } from "react-icons/fa";  
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './productCategories.css';
import Instance from "../../AxiosConfig";
import AddCoupon from "../Coupon/AddCoupon";
import { RiDeleteBinLine } from "react-icons/ri";
import { EditProductss } from "./EditProductss";
import { useNavigate } from "react-router-dom";


const ProductCard = ({ category, images, title, weight, price, oldPrice, brand, rating, reviews, handleOpenEditProductModal, handleOpenAddCouponModal, productId, handleDeleteProduct, discountPercentage }) => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const { confirm } = Modal;
  
  const showDeleteConfirm = (productId) => {
    confirm({
      title: 'Are you sure you want to delete this product?',
      content: 'Once deleted, the product cannot be recovered.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDeleteProduct(productId);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };



  return (
    <div className="product-category-card ">
      <div className="d-flex justify-content-between">
        <p>{productId} </p>
        <RiDeleteBinLine
          className="delete_icon"
          style={{ fontSize: '28px', cursor: 'pointer' }}
          onClick={() => showDeleteConfirm(productId)}
        />
      </div>
      <div className="slider-container">
        <Slider {...sliderSettings}>
          {images.length > 0 ? (
            images.map((imgSrc, index) => (
              <img key={index} src={`data:image/jpeg;base64,${imgSrc}`} className="product-category-img" alt={title} />
            ))
          ) : (
            <img src="https://via.placeholder.com/150" className="product-category-img" alt={title} /> 
          )}
        </Slider>
      </div>
      <div className="d-flex justify-content-between mt-4">
        <h6>{title}</h6>
        <p>({weight})</p>
      </div>
      <div></div>
      <div className="d-flex gap-2">

        <div className="d-flex gap-2">
          <p>₹ {price} /</p>
          <p style={{ textDecoration: "line-through" }}>₹ {oldPrice}</p>
          <p className="productCategories-discount">{discountPercentage}% off</p>
        </div>

      </div>
      <div className="d-flex gap-2 pt-2">
        <Rate disabled allowHalf value={rating} style={{ display: "flex", justifyContent: "start" }} />
        <p>({reviews})</p>
      </div>
      <div className="d-flex productCategories-buttons-div">
        <button className="edit-product-category mt-0" onClick={handleOpenEditProductModal}>Edit Product</button>
        <button className="add_new_coupon_button mt-4" onClick={handleOpenAddCouponModal}><FaPlus /> &nbsp; New Coupon</button>
      </div>
    </div>
  );
};



const ProductCategoriesList = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCouponModalVisible, setIsCouponModalVisible] = useState(false);
  const [products, setProducts] = useState({});
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [isEditModalClosed, setIsEditModalClosed] = useState(false);
  const navigate = useNavigate();

  const handleViewAll = (category) => {
    navigate(`/view-all-products`, { state: { category } });
  };

  const handleOpenAddCouponModal = async (productId) => {
    console.log("hduejdu:", productId);
    setSelectedProductId(productId);
    setIsCouponModalVisible(true);
  };

  const handleOpenEditProductModal = async (productId) => {
    console.log("Product ID:", productId);
    try {
      const response = await Instance.get(`/api/v1/products/${productId}`);
      setSelectedProduct(response.data);
      console.log("df:", response.data)
      setIsModalVisible(true);
    } catch (error) {
      console.error("Failed to fetch product details:", error);
    }
  };

  const handleDeleteProduct = async (productId) => {
    try {
      await Instance.delete(`/api/v1/products/${productId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adminAuthToken")}`,
          "Content-Type": "application/json",
        }
      });
      setProducts((prevProducts) => {
        const updatedProducts = { ...prevProducts };
        Object.keys(updatedProducts).forEach((category) => {
          updatedProducts[category] = updatedProducts[category].filter(product => product.productId !== productId);
        });
        return updatedProducts;
      });
      message.success("Product deleted successfully");
    } catch (error) {
      console.error("Failed to delete product:", error);
    }
  };
  const handleDeleteCategory = async (category) => {
    if (products[category] && products[category].length > 0) {
      message.error(`Category "${category}" has products. Please delete the products before deleting the category.`);
    } else {
      Modal.confirm({
        title: `Are you sure you want to delete the category "${category}"?`,
        content: 'Once deleted, this category cannot be recovered.',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        async onOk() {
          try {
            await Instance.delete(`/api/v1/categories/${category}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("adminAuthToken")}`,
              },
            });
            setProducts((prevProducts) => {
              const updatedProducts = { ...prevProducts };
              delete updatedProducts[category];
              return updatedProducts;
            });
            message.success(`Category "${category}" deleted successfully.`);
          } catch (error) {
            console.error("Failed to delete category:", error);
            message.error(`Failed to delete category "${category}". Please try again.`);
          }
        },
        onCancel() {
          console.log('Category deletion cancelled');
        },
      });
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const response = await Instance.get("/api/v1/products/grouped-by-category");
        setProducts(response.data);
        setLoading(false);
        console.log("Grouped by category", response.data)
      } catch (error) {
        console.error("Error fetching products:", error);
        setLoading(false);
      }
    };
    fetchProducts();
  }, [isEditModalClosed]);

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };
  if (loading) {
    return <div className="loading"><Spin size="large" /></div>;
  }

  return (
    <div className="container">
      {Object.keys(products).map((category) => (
        <div key={category}>
          <div className="d-flex justify-content-between align-items-center mt-4 mb-3">
            <h5 className="" style={{ textAlign: "left" }}>
              {category.charAt(0).toUpperCase() + category.slice(1)}
            </h5>
            <div className="d-flex gap-4">
              <button className="ViewAll-button" onClick={() => handleViewAll(category)}>View All</button>
              <RiDeleteBinLine
                className="delete_icon"
                style={{ fontSize: '28px', cursor: 'pointer' }}
                onClick={() => handleDeleteCategory(category)}
              />
            </div>
          </div>
          {products[category].length > 0 ? (
            <Slider {...sliderSettings}>
              {products[category].map((product, index) => (
                <div key={index} className="all-categories-card">
                  <ProductCard
                    category={category}
                    images={[product.imageData, product.imageData1, product.imageData2]}
                    productId={product.productId}
                    title={product.title}
                    weight={product.weight || '50kg'}
                    brand={product.brand}
                    price={product.discountedPrice}
                    oldPrice={product.price}
                    rating={product.numRatings}
                    reviews={product.totalReviews}
                    discountPercentage={product.discountPercentage}
                    handleOpenEditProductModal={() => handleOpenEditProductModal(product.productId)}
                    handleOpenAddCouponModal={() => handleOpenAddCouponModal(product.productId)}
                    handleDeleteProduct={handleDeleteProduct}
                  />
                </div>
              ))}
            </Slider>
          ) : (
            <div>No {category} products available</div>
          )}
        </div>
      ))}
      <AddCoupon isModalVisible={isCouponModalVisible} setIsModalVisible={setIsCouponModalVisible} productId={selectedProductId} />
      <EditProductss
        isModalVisible={isModalVisible}
        setIsModalVisible={(visible) => {
          setIsModalVisible(visible);
          if (!visible) {
            setIsEditModalClosed(!isEditModalClosed);
          }
        }}
        selectedProduct={selectedProduct}
      />    </div>
  );
};

export default ProductCategoriesList;
