import React, { useState, useEffect } from "react";
import { Modal, Input, Form, message, DatePicker, Select } from "antd";
import './ManageAdmins.css';
import Instance from "../../AxiosConfig";
const NewUser = ({ isModalVisible, setIsModalVisible }) => {
    const [roleOptions, setRoleOptions] = useState([]);
    const [selectedRole, setSelectedRole] = useState('');
    const [form] = Form.useForm();

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            const userData = {
                fullName: values.name,
                email: values.email,
                password: values.password,
                mobile: values.mobile,
                dateOfBirth: values.dob.format("YYYY-MM-DD"),
                roleName:values.role,
                streetAddress: values.address,
            };

            const response = await Instance.post('/api/v1/admin/create', userData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("adminAuthToken")}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200 || response.status === 201) {
                console.log(response.data);
                setIsModalVisible(false);
                message.success('User created successfully!');
                form.resetFields();
            }
        } catch (error) {
            if (error.response) {
                message.error(`Error: ${error.response.data.message}`);
            } else {
                message.error('Please complete the form correctly.');
                console.log('Validate Failed:', error);
            }
        }
    };

    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const response = await Instance.get("api/v1/roleAccess/roles", {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("adminAuthToken")}`,
                        "Content-Type": "application/json",
                    },
                });
                console.log("Roles: ", response.data);
    
                const filteredRoles = response.data.filter(role => role.roleName !== 'ROLE_NORMAL');
    
                const roles = filteredRoles.map(role => ({
                    value: role.roleName.replace(/_/g, ' '),
                    label: role.roleName.replace(/_/g, ' ')
                }));
                console.log("filtered role:", roles);
                setRoleOptions(roles);
                if (roles.length > 0) {
                    setSelectedRole(roles[0].value);
                }
            } catch (error) {
                console.error("Error fetching roles:", error);
            }
        };
    
        fetchRoles();
    }, []);

    return (
        <Modal
            title="New User"
            visible={isModalVisible}
            onOk={handleSubmit}
            onCancel={() => setIsModalVisible(false)}
            footer={[
                <div className="d-flex justify-content-end gap-2" key="footer">
                    <button
                        style={{
                            backgroundColor: "#f6f6f6",
                            border: "none",
                            borderRadius: "15px",
                            padding: "10px 20px",
                        }}
                        onClick={() => setIsModalVisible(false)}
                    >
                        Discard
                    </button>
                    <button
                        style={{
                            backgroundColor: "#1b3a57",
                            border: "none",
                            borderRadius: "15px",
                            color: "#fff",
                            padding: "10px 20px",
                        }}
                        onClick={handleSubmit}
                    >
                        Save 
                    </button>
                </div>,
            ]}
        >
            <div className="add-brand-section pt-2">
                <Form
                    form={form}
                    layout="vertical"
                    name="new_user_form"
                >
                    <div className="row">
                        <div className="col-lg-12 mb-1">
                            <Form.Item
                                label={<span className="manageadmins-new-user-label">Full Name</span>}
                                name="name"
                                rules={[{ required: true, message: 'Please input your full name!' }]}
                            >
                                <Input
                                    placeholder="Full Name"
                                    className="form-control custom-placeholder"
                                />
                            </Form.Item>
                        </div>
                        <div className="col-lg-12 mb-1">
                            <Form.Item
                                label={<span className="manageadmins-new-user-label">Email</span>}
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your email!'
                                    },
                                    {
                                        type: 'email',
                                        message: 'The input is not valid E-mail!',
                                    }
                                ]}
                            >
                                <Input
                                    placeholder="Email"
                                    className="form-control custom-placeholder"
                                />
                            </Form.Item>
                        </div>
                        <div className="col-lg-12 mb-1">
                            <Form.Item
                                label={<span className="manageadmins-new-user-label">Password</span>}
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password!'
                                    },
                                    {
                                        pattern: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/),
                                        message: 'Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and a special character!',
                                    }
                                ]}
                            >
                                <Input
                                    type="password"
                                    placeholder="Password"
                                    className="form-control custom-placeholder"
                                />
                            </Form.Item>
                        </div>
                        <div className="col-lg-12 mb-1">
                            <Form.Item
                                label={<span className="manageadmins-new-user-label">Mobile</span>}
                                name="mobile"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your mobile number!'
                                    },
                                    {
                                        pattern: new RegExp(/^[0-9]{10}$/),
                                        message: 'The input is not a valid mobile number!',
                                    }
                                ]}
                            >
                                <Input
                                    placeholder="Mobile"
                                    className="form-control custom-placeholder"
                                />
                            </Form.Item>
                        </div>
                        <div className="col-lg-12 mb-1">
                            <Form.Item
                                label={<span className="manageadmins-new-user-label">Date of Birth</span>}
                                name="dob"
                                rules={[{ required: true, message: 'Please input your date of birth!' }]}
                            >
                                <DatePicker
                                    placeholder="Date of Birth"
                                    className="form-control custom-placeholder"
                                    format="YYYY-MM-DD"
                                />
                            </Form.Item>
                        </div>
                        <div className="col-lg-12 mb-1">
                            <Form.Item
                                label={<span className="manageadmins-new-user-label">Role Name</span>}
                                rules={[{ required: true, message: 'Please select a role!' }]}
                                name="role"
                            >
                                <Select
                                    options={roleOptions}
                                    value={selectedRole}
                                    onChange={(value) => setSelectedRole(value)}
                                    placeholder="Select a role"
                                    style={{
                                        width: '100%',
                                    }}
                                />

                            </Form.Item>

                        </div>
                        <div className="col-lg-12 mb-1">
                            <Form.Item
                                label={<span className="manageadmins-new-user-label">Address</span>}
                                name="address"
                                rules={[{ required: true, message: 'Please input your address!' }]}
                            >
                                <Input.TextArea
                                    placeholder="Address"
                                    className="form-control custom-placeholder"
                                />
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </div>
        </Modal>
    );
};

export default NewUser;
