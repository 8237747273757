import React, { useState } from "react";
import "./Settings.css";
import { message, Space, TimePicker } from "antd";
import Instance from "../../AxiosConfig";

const onChange = (time, timeString) => {
  console.log(time, timeString);
};

const StoreInformation = () => {
  const [form, setForm] = useState({
    storeName: "",
    email: "",
    address: "",
    city: "",
    state: "Karnataka",
    zipCode: "",
    country: "",
    phoneNumber: "",
    website: "",
    openingHours: null,
    closingHours: null,
    latitude: "",
    longitude: "",
    storeManager: "",
    description: "",
    enableRatings: "",
    enableReviews: "",
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const validate = () => {
    const newErrors = {};
    if (!form.storeName) newErrors.storeName = "Store Name is required";
    if (!form.email) newErrors.email = "Email is required";
    else if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(form.email))
      newErrors.email = "Email is invalid. Example: example@gmail.com";
    if (!form.address) newErrors.address = "Store Address is required";
    if (!form.city) newErrors.city = "City is required";
    if (!form.state) newErrors.state = "State is required";
    if (!form.zipCode) newErrors.zipCode = "ZipCode is required";
    else if (!/^\d{1,6}$/.test(form.zipCode))
      newErrors.zipCode = "ZipCode must be a number up to 6 digits";
    if (!form.country) newErrors.country = "Country is required";
    if (!form.phoneNumber) newErrors.phoneNumber = "Phone Number is required";
    else if (!/^\d{10}$/.test(form.phoneNumber))
      newErrors.phoneNumber = "Phone Number must be 10 digits";
    if (!form.website) newErrors.website = "Website is required";
    if (!form.latitude) newErrors.latitude = "Latitude is required";
    else if (form.latitude.length > 20) {
      newErrors.latitude = "Latitude must be up to 20 characters";
    }
    if (!form.longitude) newErrors.longitude = "Longitude is required";
    else if (form.longitude.length > 20) {
      newErrors.longitude = "Longitude must be up to 20 characters";
    }
    if (!form.openingHours)
      newErrors.openingHours = "Opening Hours are required";
    if (!form.closingHours)
      newErrors.closingHours = "Closing Hours are required";
    if (!form.storeManager)
      newErrors.storeManager = "Store Manager is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "zipCode") {
        // Allow only numbers and restrict to 6 digits
        const numericValue = value.replace(/\D/g, "").slice(0, 6);
        setForm({
            ...form,
            [name]: numericValue,
        });
    } else if (name === "phoneNumber") {
        // Restrict to 10 digits and starting with 4-9
        let numericValue = value.replace(/\D/g, "").slice(0, 10);
        if (numericValue.length > 0) {
            // Ensure the first digit is between 4 and 9
            if (numericValue[0] < '4' || numericValue[0] > '9') {
                numericValue = numericValue.slice(1); // Remove the invalid first digit
            }
        }
        setForm({
            ...form,
            [name]: numericValue,
        });
    } else if (name === "latitude" || name === "longitude") {
        // Restrict to 20 characters for latitude and longitude
        if (value.length <= 20) {
            setForm({
                ...form,
                [name]: value,
            });
        }
    } else {
        setForm({
            ...form,
            [name]: value,
        });
    }
};

  const formatTime = (time) => {
    if (!time) return "";
    const hours = time.getHours().toString().padStart(2, "0");
    const minutes = time.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const handleTimeChange = (field, time) => {
    const formattedTime = time ? formatTime(time.toDate()) : null;
    setForm({
      ...form,
      [field]: formattedTime,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      console.log("Form data to be sent:", form);

      const formData = new FormData();
      for (const key in form) {
        if (form.hasOwnProperty(key)) {
          if (key === "openingHours" || key === "closingHours") {
            const timeValue = form[key];
            formData.append(key, timeValue || ""); // Use formatted time or empty string
          } else {
            formData.append(key, form[key]);
          }
        }
      }
      try {
        const response = await Instance.post(
          "/api/v1/store-info/create",
          form,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("adminAuthToken")}`,
              "Content-Type": "application/json",
            },
          }
        );
        console.log("Form submitted successfully:", response.data);
        setForm({
          ...form,
          storeName: response.data.storeName,
          email: response.data.email,
          address: response.data.address,
          city: response.data.city,
          state: response.data.state,
          zipCode: response.data.zipCode,
          country: response.data.country,
          phoneNumber: response.data.phoneNumber,
          website: response.data.website,
          openingHours: response.data.openingHours
            ? formatTime(new Date(response.data.openingHours))
            : null,
          closingHours: response.data.closingHours
            ? formatTime(new Date(response.data.closingHours))
            : null,
          latitude: response.data.latitude,
          longitude: response.data.longitude,
          storeManager: response.data.storeManager,
          description: response.data.description,
          enableRatings: response.data.enableRatings,
          enableReviews: response.data.enableReviews,
        });
        message.success("Store information saved successfully!");
        console.log("submitted store:", response.data);
        setErrorMessage("");
      } catch (error) {
        console.error(
          "Error submitting form:",
          error.response ? error.response.data : error.message
        );
        message.error("Failed to save store information. Please try again.");
        setSuccessMessage("");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <h4 className="text-align-left d-flex pb-2">Store Information</h4>
      <form onSubmit={handleSubmit}>
        <div className="row pb-2 settings-personalinfo-row">
          <div className="col">
            <label className="text-align-left d-flex pb-2">
              Store Name<span className="required-star">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="storeName"
              placeholder="Enter the store name"
              value={form.storeName}
              onChange={handleChange}
              style={{ padding: "7px", borderRadius: "7px" }}
            />
            {errors.storeName && (
              <span className="text-danger">{errors.storeName}</span>
            )}
          </div>
          <div className="col storeinfo-email">
            <label className="text-align-left d-flex pb-2">
              E-mail<span className="required-star">*</span>
            </label>
            <input
              type="email"
              className="form-control"
              name="email"
              placeholder="Contact email address of the store"
              value={form.email}
              onChange={handleChange}
              style={{ padding: "7px", borderRadius: "7px" }}
            />
            {errors.email && (
              <span className="text-danger">{errors.email}</span>
            )}
          </div>
        </div>
        <div className="row pb-4 settings-personalinfo-row">
          <div className="col">
            <label className="text-align-left d-flex pb-2">
              Store Address<span className="required-star">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="address"
              placeholder="Enter store address"
              value={form.address}
              onChange={handleChange}
              style={{ padding: "7px", borderRadius: "7px" }}
            />
            {errors.address && (
              <span className="text-danger">{errors.address}</span>
            )}
          </div>
        </div>
        <div className="row pb-2 settings-personalinfo-row mt-1">
          <div className="col storeinfo-email">
            <label className="text-align-left d-flex pb-2">
              City<span className="required-star">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="city"
              placeholder="Enter the city where the store is located."
              value={form.city}
              onChange={handleChange}
              style={{ padding: "7px", borderRadius: "7px" }}
            />
            {errors.city && <span className="text-danger">{errors.city}</span>}
          </div>
          <div className="col">
            <label
              htmlFor="inputState"
              className="form-label text-align-left d-flex pb-1"
            >
              State<span className="required-star">*</span>
            </label>
            <select
              id="inputState"
              className="form-select store-information-state-options"
              name="state"
              value={form.state}
              onChange={handleChange}
              style={{ padding: "7px", borderRadius: "7px" }}
            >
              <option>Karnataka</option>
              <option>Thamilnadu</option>
              <option>Andhrapradesh</option>
              <option>Kerala</option>
              <option>Arunachal Pradesh</option>
              <option>Assam</option>
              <option>Bihar</option>
              <option>Chhattisgarh</option>
              <option>Goa</option>
              <option>Gujarat</option>
              <option>Haryana</option>
              <option>Himachal Pradesh</option>
              <option>Jharkhand</option>
              <option>Madhya Pradesh</option>
              <option>Maharashtra</option>
              <option>Manipur</option>
              <option>Meghalaya</option>
              <option>Mizoram</option>
              <option>Nagaland</option>
              <option>Odisha</option>
              <option>Punjab</option>
              <option>Rajasthan</option>
              <option>Sikkim</option>
              <option>Telangana</option>
              <option>Tripura</option>
              <option>Uttar Pradesh</option>
              <option>Uttarakhand</option>
              <option>West Bengal</option>
              <option>Chandigarh</option>
              <option>Delhi</option>
              <option>Jammu and Kashmir</option>
            </select>
            {errors.state && (
              <span className="text-danger">{errors.state}</span>
            )}
          </div>
        </div>
        <div className="row pb-2 settings-personalinfo-row mt-3">
          <div className="col storeinfo-email">
            <label className="text-align-left d-flex pb-2">
              ZipCode<span className="required-star">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="zipCode"
              placeholder="Enter the zipcode"
              value={form.zipCode}
              onChange={handleChange}
              style={{ padding: "7px", borderRadius: "7px" }}
            />
            {errors.zipCode && (
              <span className="text-danger">{errors.zipCode}</span>
            )}
          </div>
          <div className="col">
            <label className="text-align-left d-flex pb-2">
              Country<span className="required-star">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="country"
              placeholder="The country where the store is located"
              value={form.country}
              onChange={handleChange}
              style={{ padding: "7px", borderRadius: "7px" }}
            />
            {errors.country && (
              <span className="text-danger">{errors.country}</span>
            )}
          </div>
        </div>
        <div className="row pb-2 settings-personalinfo-row mt-2">
          <div className="col">
            <label className="text-align-left d-flex pb-2">
              Phone Number<span className="required-star">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="phoneNumber"
              placeholder="The contact number of the store"
              value={form.phoneNumber}
              onChange={handleChange}
              style={{ padding: "7px", borderRadius: "7px" }}
            />
            {errors.phoneNumber && (
              <span className="text-danger">{errors.phoneNumber}</span>
            )}
          </div>
          <div className="col storeinfo-email">
            <label className="text-align-left d-flex pb-2">
              Website<span className="required-star">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="website"
              placeholder="The website URL of the store."
              value={form.website}
              onChange={handleChange}
              style={{ padding: "7px", borderRadius: "7px" }}
            />
            {errors.website && (
              <span className="text-danger">{errors.website}</span>
            )}
          </div>
        </div>
        <div className="row pb-2 settings-personalinfo-row mt-3">
          <div className="col">
            <label className="text-align-left d-flex pb-2">
              Opening Hours<span className="required-star">*</span>
            </label>
            <TimePicker
              use12Hours
              format="HH:mm:ss A"
              onChange={(time) => handleTimeChange("openingHours", time)}
              style={{width:"100%",borderRadius:"7px",padding:"7px"}}
              className="time-sector-width"
            />
            {errors.openingHours && (
              <span className="text-danger">{errors.openingHours}</span>
            )}
          </div>
          <div className="col storeinfo-email">
            <label className="text-align-left d-flex pb-2">
              Closing Hours<span className="required-star">*</span>
            </label>
            <TimePicker
              use12Hours
              format="HH:mm:ss A"
              onChange={(time) => handleTimeChange("closingHours", time)}
              style={{width:"100%", borderRadius:"7px", padding:"7px"}}
              className="time-sector-width"
            />
            {errors.closingHours && (
              <span className="text-danger">{errors.closingHours}</span>
            )}
          </div>
        </div>
        <div className="row pb-2 settings-personalinfo-row mt-2">
          <div className="col">
            <label className="text-align-left d-flex pb-2">
              Latitude<span className="required-star">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="latitude"
              placeholder="Enter the Latitude here"
              value={form.latitude}
              onChange={handleChange}
              style={{ padding: "7px", borderRadius: "7px" }}
            />
            {errors.latitude && (
              <span className="text-danger">{errors.latitude}</span>
            )}
          </div>
          <div className="col storeinfo-email">
            <label className="text-align-left d-flex pb-2">
              Longitude<span className="required-star">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="longitude"
              placeholder="Enter the Longitude here"
              value={form.longitude}
              onChange={handleChange}
              style={{ padding: "7px", borderRadius: "7px" }}
            />
            {errors.longitude && (
              <span className="text-danger">{errors.longitude}</span>
            )}
          </div>
        </div>
        <div className="row pb-2 settings-personalinfo-row mt-3">
          <div className="col">
            <label className="text-align-left d-flex pb-2">
              Store Manager<span className="required-star">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="storeManager"
              placeholder="Enter the store manager's name"
              value={form.storeManager}
              onChange={handleChange}
              style={{ padding: "7px", borderRadius: "7px" }}
            />
            {errors.storeManager && (
              <span className="text-danger">{errors.storeManager}</span>
            )}
          </div>
          <div className="col storeinfo-email">
            <label className="text-align-left d-flex pb-2">Description</label>
            <textarea
              className="form-control"
              name="description"
              placeholder="A brief description of the store"
              value={form.description}
              onChange={handleChange}
              id="floatingTextarea"
            ></textarea>
          </div>
        </div>
        {/* <h5 className="text-align-left d-flex">Reviews & Comments</h5>
        <span
          className="text-align-left d-flex pb-2 storeinfo-reviews-comments"
          style={{ fontSize: "14px", color: "#667085" }}
        >
          All Settings related to feedback and comments system.
        </span> */}
        <h5 className="text-align-left d-flex pt-2">Product Ratings</h5>
        <div className="d-flex gap-4">
        <div className="form-check text-align-left d-flex gap-2">
          <input
            className="form-check-input"
            type="checkbox"
            id="gridCheck1"
            onChange={(e) =>
              setForm({ ...form, enableRatings: e.target.checked })
            }
          />
          <label className="form-check-label" htmlFor="gridCheck1">
            Enables star ratings on products
          </label>
        </div>
        <div className="form-check text-align-left d-flex gap-2">
          <input
            className="form-check-input "
            type="checkbox"
            id="gridCheck2"
            onChange={(e) =>
              setForm({ ...form, enableReviews: e.target.checked })
            }
          />
          <label
            className="form-check-label storeinfo-form-check-label-rating"
            htmlFor="gridCheck2"
          >
            Enables reviews on products
          </label>
        </div>
        </div>
        <div className="d-flex justify-content-end pt-4 gap-2">
          {/* <button
            type="button"
            style={{
              backgroundColor: "#f6f6f6",
              border: "none",
              borderRadius: "15px",
              padding: "10px 20px",
            }}
          >
            Discard
          </button> */}
          <button
            type="submit"
            style={{
              backgroundColor: "#1b3a57",
              border: "none",
              borderRadius: "15px",
              color: "#fff",
              padding: "10px 20px",
            }}
          >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default StoreInformation;